import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'periodConfigurationRadioButton',
    'periodSelectWrapper',
    'periodSelect',
    'periodPreview',
    'appliesToAmenitiesRadioButton',
    'amenitiesSelectWrapper',
    'amenitiesSelect',
    'appliesWhenFixedRentableCheckBoxWrapper',
    'appliesWhenAmenitiesCheckBoxWrapper',
  ];

  connect() {
    if (this.hasPeriodConfigurationRadioButtonTarget) {
      $(this.periodConfigurationRadioButtonTargets).on('change', this.handlePeriodConfigurationRadioButtonChange);
      $(this.periodConfigurationRadioButtonTargets).trigger('change');
    }

    if (this.hasPeriodSelectTarget) {
      $(this.periodSelectTarget).on('change', this.handlePeriodSelectChange);
    }

    if (this.hasAppliesToAmenitiesRadioButtonTarget) {
      $(this.appliesToAmenitiesRadioButtonTargets).on('change', this.handleAppliesToAmenitiesRadioButtonChange);
      $(this.appliesToAmenitiesRadioButtonTargets).trigger('change');
    }

    if (this.hasAmenitiesSelectTarget) {
      $(this.amenitiesSelectTarget).on('change', this.handleAmenitiesSelectChange);
    }
  }

  disconnect() {
    if (this.hasPeriodConfigurationRadioButtonTarget) {
      $(this.periodConfigurationRadioButtonTargets).off('change', this.handlePeriodConfigurationRadioButtonChange);
    }

    if (this.hasPeriodSelectTarget) {
      $(this.periodSelectTarget).off('change', this.handlePeriodSelectChange);
    }

    if (this.hasAppliesToAmenitiesRadioButtonTarget) {
      $(this.appliesToAmenitiesRadioButtonTargets).off('change', this.handleAppliesToAmenitiesRadioButtonChange);
    }

    if (this.hasAmenitiesSelectTarget) {
      $(this.amenitiesSelectTarget).off('change', this.handleAmenitiesSelectChange);
    }
  }

  // Event handlers

  handlePeriodConfigurationRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      const value = $radioButton.val();
      const showPeriodSelectWrapper = value == 'use_specific_period';

      $(this.periodSelectWrapperTarget).toggle(showPeriodSelectWrapper);

      if (showPeriodSelectWrapper) {
        $(this.periodSelectTarget).trigger('change');
      }
    }
  };

  handlePeriodSelectChange = () => {
    // prettier-ignore
    const periodPreviewPath =
      App.preview_park_period_path.replace('period_id_placeholder', $(this.periodSelectTarget).val());

    $(this.periodPreviewTarget).load(periodPreviewPath);
    App.tooltips();
  };

  handleAppliesToAmenitiesRadioButtonChange = (event) => {
    const $radioButton = $(event.currentTarget);

    if ($radioButton.is(':checked')) {
      const showAmenitiesSelectWrapper = $radioButton.val() == 'true';

      $(this.amenitiesSelectWrapperTarget).toggle(showAmenitiesSelectWrapper);

      if (showAmenitiesSelectWrapper) {
        $(this.amenitiesSelectTarget).trigger('change');
      } else {
        $(this.appliesWhenFixedRentableCheckBoxWrapperTarget).show();
        $(this.appliesWhenAmenitiesCheckBoxWrapperTarget).show();
      }
    }
  };

  handleAmenitiesSelectChange = () => {
    const amenitiesSelected = $(this.amenitiesSelectTarget).find('option:selected').length > 0;

    $(this.appliesWhenFixedRentableCheckBoxWrapperTarget).toggle(!amenitiesSelected);
    $(this.appliesWhenAmenitiesCheckBoxWrapperTarget).toggle(!amenitiesSelected);
  };
}
