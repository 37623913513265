document.addEventListener('turbo:before-stream-render', function (e) {
  const turboStream = e.target;

  if (turboStream.action == 'close_modal') {
    e.preventDefault();

    const modalId = turboStream.getAttribute('target');
    document.getElementById(modalId).dispatchEvent(new CustomEvent('modal.remove'));
  }

  if (turboStream.action === 'highlight_replace' || turboStream.action === 'highlight_update') {
    turboStream.setAttribute('action', turboStream.action.replace('highlight_', ''));
    const children = turboStream.templateElement.content.children;
    [...children].forEach((node) => addStimulusController(node, 'highlight'));
  }

  if (turboStream.action == 'redirect_to') {
    e.preventDefault();

    const redirectPath = turboStream.getAttribute('location');
    const redirectLocation = new URL(redirectPath, window.location);

    if (window.location.toString() === redirectLocation.toString()) {
      window.location.reload();
    } else if (urlWithoutAnchor(window.location) === urlWithoutAnchor(redirectLocation)) {
      // If only the anchor differs, we need to add a timestamp to the URL to force a reload.
      window.location.href = urlWithTimestamp(redirectPath);
    } else {
      window.location.href = redirectPath;
    }
  }

  if (turboStream.action === 'replace_url') {
    e.preventDefault();
    const url = turboStream.getAttribute('url');
    window.history.replaceState({}, '', url);
  }
});

function addStimulusController(node, controllerName) {
  const stimulusControllers = [controllerName];

  if (node.getAttribute('data-controller')) {
    stimulusControllers.push(...node.getAttribute('data-controller').split(' '));
  }

  node.setAttribute('data-controller', stimulusControllers.join(' '));
}

function urlWithTimestamp(redirectPath) {
  const location = new URL(redirectPath, window.location);
  const searchParams = new URLSearchParams(location.search);

  searchParams.set('t', Date.now());
  location.search = searchParams.toString();

  return location.toString();
}

function urlWithoutAnchor(location) {
  const url = new URL(location);
  url.hash = '';
  return url.toString();
}
