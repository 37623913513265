import { Controller } from '@hotwired/stimulus';
import FormUpdater from './form_updater';

export default class extends Controller {
  static targets = ['forPets', 'forPetsWrapper', 'numberOfPets'];

  constructor() {
    super(...arguments);
    this.formUpdater = new FormUpdater(this);
  }

  connect() {
    $(this.element).on('change', '.js-form--mutator', this.handleMutatorChange);
  }

  disconnect() {
    $(this.element).off('change', '.js-form--mutator', this.handleMutatorChange);
  }

  handleMutatorChange = (event) => {
    const $input = $(event.target);

    if (this.forPetsTargets.length > 0 && $input.is(this.forPetsTargets)) {
      this.toggleForPetsField($input);
    } else if (this.hasNumberOfPetsTarget && $input.is(this.numberOfPetsTarget)) {
      this.toggleNumberOfPetsField($input);
    }

    this.$orderTarget.attachLoader('large', true);
    this.formUpdater.refreshForm(this.data.get('refresh-url'));
  };

  toggleNumberOfPetsField($input) {
    if (parseInt($input.val()) > 0) {
      $(this.forPetsTargets).filter('[value="true"]').prop('checked', true); // prettier-ignore
      $(this.forPetsWrapperTarget).effect('highlight', { color: HIGHLIGHT_COLOR }, HIGHLIGHT_DURATION);
    }
  }

  toggleForPetsField($input) {
    const $numberOfPets = $(this.numberOfPetsTarget);
    const $numberOfPetsWrapper = $numberOfPets.closest('div');

    if ($input.is(':checked')) {
      if ($input.val() === 'false') {
        $numberOfPets.val(0);
        $numberOfPetsWrapper.slideUp('fast');
      } else {
        $numberOfPetsWrapper.slideDown('fast');
        $numberOfPetsWrapper.effect('highlight', { color: HIGHLIGHT_COLOR }, HIGHLIGHT_DURATION);
      }
    }
  }
}
