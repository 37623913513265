import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  handleSubmit(event) {
    const datepicker = this.element.querySelector('input.date_picker');

    if (!datepicker || !datepicker.value || new Date(datepicker.value) < new Date()) return;

    event.preventDefault();

    if (confirm(this.data.get('message'))) {
      event.target.submit();
    }
  }
}
