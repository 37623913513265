import { Controller } from '@hotwired/stimulus';
import tippy, { roundArrow } from 'tippy.js';

const DEFAULT_POPPER_OPTIONS = {
  allowHTML: true,
  interactive: true,
  trigger: 'click',
  maxWidth: 'none',
  duration: 0,
  offset: [0, 8],
  zIndex: 0,
};

export default class extends Controller {
  static targets = ['pane', 'trigger'];

  static values = {
    placement: { type: String, default: 'bottom-start' },
    theme: { type: String, default: 'popover' },
    arrow: { type: Boolean, default: false },
  };

  connect() {
    this.tippyInstance = tippy(this.triggerTarget, {
      ...DEFAULT_POPPER_OPTIONS,
      content: this.paneTarget.content.cloneNode(true),
      appendTo: this.portal,
      onShown: this.handleShown,
      onShow: this.handleShow,
      onHide: this.handleHide,
      placement: this.placementValue,
      theme: this.themeValue,
      arrow: this.arrowValue,
      popperOptions: this.popperOptions,
    });
  }

  disconnect() {
    this.tippyInstance.destroy();
    this.tippyInstance = null;
  }

  handleShown = ({ popper }) => {
    $(popper).find('[autofocus], [data-autofocus]').trigger('focus');
  };

  handleShow = () => {
    $(this.triggerTarget).addClass(this.activeClass);
  };

  handleHide = () => {
    $(this.triggerTarget).removeClass(this.activeClass);
  };

  getOppositeVariationPlacement = (placement) => {
    const oppositeVariationPlacement = {
      top: 'bottom',
      bottom: 'top',
      left: 'right',
      right: 'left',
    };

    return placement.replace(/top|bottom|right|left/g, (matched) => oppositeVariationPlacement[matched]);
  };

  getFallbackPlacements = () => {
    return [this.getOppositeVariationPlacement(this.placementValue), 'auto'];
  };

  get popperOptions() {
    return { modifiers: [{ name: 'flip', options: { fallbackPlacements: this.getFallbackPlacements() } }] };
  }

  get activeClass() {
    return $(this.triggerTarget).data('activeClass') || 'active';
  }

  get portal() {
    return document.getElementById('portal');
  }
}
