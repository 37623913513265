import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';

export default class extends Controller {
  static targets = [
    'emptyState',
    'suggestions',
    'firstName',
    'lastName',
    'company',
    'email',
    'postalcode',
    'address',
    'city',
    'phone',
    'countryCode',
    'vatNr',
    'dateOfBirth',
    'receiveNewsletter',
    'locale',
    'title',
    'isCompany',
  ];

  fetchContacts = debounce(() => {
    this.suggestionsTarget.innerHTML = '';
    this.suggestionsTarget.style.display = 'block';
    this.emptyStateTarget.style.display = 'none';

    let params = {};

    this.addParam(params, 'search[company]', this.companyTarget.value);
    this.addParam(params, 'search[first_name]', this.firstNameTarget.value);
    this.addParam(params, 'search[last_name]', this.lastNameTarget.value);
    this.addParam(params, 'search[email]', this.emailTarget.value);
    this.addParam(params, 'search[postalcode]', this.postalcodeTarget.value);
    this.addParam(params, 'search[address]', this.addressTarget.value);

    // If there are no parameters, hide the suggestions and show emptyState
    if (Object.keys(params).length === 0) {
      this.suggestionsTarget.style.display = 'none';
      this.emptyStateTarget.style.display = 'block';

      return;
    }

    fetch(this.data.get('source-url') + '?' + new URLSearchParams(params), {
      headers: {
        Accept: 'text/html',
      },
    })
      .then((response) => response.text())
      .then((html) => {
        this.suggestionsTarget.innerHTML = html;
      });
  }, 300);

  fillInputs = (event) => {
    const contact = event.params.payload;

    this.lastNameTarget.value = contact.last_name;
    this.firstNameTarget.value = contact.first_name;
    this.emailTarget.value = contact.email;
    this.postalcodeTarget.value = contact.postalcode;
    this.addressTarget.value = contact.address;
    this.cityTarget.value = contact.city;
    this.phoneTarget.value = contact.phone;
    this.countryCodeTarget.value = contact.country_code;
    this.companyTarget.value = contact.company;
    this.vatNrTarget.value = contact.vat_nr;
    this.dateOfBirthTarget.value = contact.date_of_birth;
    this.receiveNewsletterTarget.checked = contact.receive_newsletter;
    this.localeTarget.value = contact.locale;
    this.localeTarget.dispatchEvent(new Event('change'));

    // Handles this.element.booking_title as a NodeList when multiple locales exist, otherwise treat it as a single node.
    if (NodeList.prototype.isPrototypeOf(this.titleTarget)) {
      this.titleTarget.forEach((element) => {
        element.value = contact.title;
      });
    } else {
      this.titleTarget.value = contact.title;
    }

    const isCompany = contact.company !== null;

    this.isCompanyTargets.forEach((element) => {
      if (element.id.includes('true')) {
        element.checked = isCompany;
      } else {
        element.checked = !isCompany;
      }
      element.dispatchEvent(new Event('change'));
    });
  };

  addParam = (params, key, value) => {
    if (value) {
      params[key] = value;
    }
  };
}
