import { Controller } from '@hotwired/stimulus';
import { addScrollableTarget, disablePageScroll, enablePageScroll, removeScrollableTarget } from 'scroll-lock';

const ESCAPE_KEY = 27;

export default class extends Controller {
  static targets = ['overlay', 'dialog'];

  connect() {
    this.overlayTarget.addEventListener('mousedown', this.handleOverlayMouseDown);
    this.overlayTarget.addEventListener('mouseup', this.handleOverlayMouseUp);
    this.dialogTarget.addEventListener('dialog.close', this.handleDialogClose);
    this.element.addEventListener('modal.remove', this.handleModalRemove);
    // Use keyup because keydown will conflict with Safari confirms
    // Directly closing the confirm after opening it on ESC key
    document.addEventListener('keyup', this.handleKeyPress);
    this.overlayTarget.style.animationName = this.data.get('animation');

    addScrollableTarget(this.element);
    disablePageScroll();
  }

  disconnect() {
    this.overlayTarget.removeEventListener('mousedown', this.handleOverlayMouseDown);
    this.overlayTarget.removeEventListener('mouseup', this.handleOverlayMouseUp);
    this.dialogTarget.removeEventListener('dialog.close', this.handleDialogClose);
    this.element.removeEventListener('modal.remove', this.handleModalRemove);
    document.removeEventListener('keyup', this.handleKeyPress);

    removeScrollableTarget(this.element);
    enablePageScroll();
  }

  handleOverlayMouseDown = (e) => {
    this.mouseDownTarget = e.target;
  };

  handleOverlayMouseUp = () => {
    // Only close modal if mouse down event originates from overlay element.
    if (this.overlayTarget.contains(this.mouseDownTarget) && !this.dialogTarget.contains(this.mouseDownTarget)) {
      this.closeModal();
    }
  };

  handleDialogClose = (e) => {
    this.closeModal();
    e.stopPropagation();
  };

  handleModalRemove = () => {
    removeModal(this);
    removeModalIdFromUrlIfNeeded();
  };

  closeModal = () => {
    if (!this.data.has('confirmDiscardChanges') || !this.hasUnsavedChanges || $.rails.confirm(this.data.get('confirmDiscardChanges'))) {
      removeModal(this);
      removeModalIdFromUrlIfNeeded();
    }
  };

  handleKeyPress = (e) => {
    if (e.keyCode === ESCAPE_KEY) {
      this.closeModal();
      e.stopPropagation();
    }
  };

  get hasUnsavedChanges() {
    return this.application.getControllerForElementAndIdentifier(this.dialogTarget, 'modal-dialog')?.hasUnsavedChanges;
  }
}

function removeModalIdFromUrlIfNeeded() {
  // Don't update URL if modal is opened from a srcdoc iframe.
  if (window.location.href === 'about:srcdoc') return;

  const url = new URL(window.location.href);
  if (url.searchParams.has('modal_id')) {
    url.searchParams.delete('modal_id');
    history.replaceState({}, '', url);
  }
}

export function removeModal(modal) {
  modal.overlayTarget.addEventListener('animationend', () => {
    modal.element.remove();
    removeModalIdFromUrlIfNeeded();
  });

  reverseAnimation(modal.overlayTarget, modal.data.get('animation'));
  reverseAnimation(modal.dialogTarget, modal.dialogTarget.dataset.modalDialogAnimation);
}

function reverseAnimation(element, animationName) {
  element.style.animationFillMode = 'forwards';
  element.style.animationDirection = 'reverse';
  element.style.animationName = 'none';
  // See https://stackoverflow.com/a/60686560
  void element.offsetWidth;
  element.style.animationName = animationName;
}
